/* antd 공통 css */
/* datepicker css */
.MuiInputBase-root .MuiInputBase-input {
  height: 34px;
  border: 1px solid #bbb;
  border-radius: 4px 0 0 4px;
  width: 136px;
  padding: 6px 12px;
  box-sizing: border-box;
  font-size: 14px;
}

.normal_datepicker .MuiInputBase-input {
  height: 40px;
}

.all_round .MuiInputBase-input {
  border-radius: 4px;
}
.MuiInputBase-root .MuiInputBase-input#end_date {
  border-radius: 0 4px 4px 0;
}

.MuiInput-underline:before {
  display: none;
}

/* table style */
.MuiTableHead-root.large .MuiTableRow-head .MuiTableCell-head {
  padding: 16px 8px;
}
.MuiTableRow-head .MuiTableCell-head {
  background: #f8f8f8;
  color: #888;
  font-size: 12px;
  padding: 7.5px 8px;
}

.MuiTableRow-head .MuiTableCell-head:first-of-type,
.MuiTableCell-root.MuiTableCell-body:first-of-type {
  padding-left: 24px;
}
.MuiTableRow-head .MuiTableCell-head:last-of-type,
.MuiTableCell-root.MuiTableCell-body:last-of-type {
  padding-right: 24px;
}

.MuiTableCell-root {
  border: none !important;
}
.tabe_tr {
  border-bottom: 1px solid #eee;
}
.MuiTableCell-root.MuiTableCell-body {
  color: #555;
  font-size: 12px;
  padding: 16px 8px;
}

.MuiTableCell-root.MuiTableCell-body.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table_blue_text > p > b {
  color: #0086e5;
}

/* popover style */
.MuiPopover-paper.MuiPaper-rounded {
  border-radius: 12px;
}
